import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useEmulator } from "../hooks/use-emulator"
import { useTerminal } from "../hooks/use-terminal"

const IndexPage = () => {
  const terminalRef = React.useRef<HTMLDivElement>(null)
  const term = useTerminal(terminalRef.current, MOTD)
  useEmulator(term)

  return (
    <Layout>
      <Seo title="H4x0r" />
      <div className="terminal-wrapper" ref={terminalRef}></div>
    </Layout>
  )
}

export default IndexPage

const MOTD = `


  eeee                  8""8""8                                                 
    88 eeeee eeeee eeee 8  8  8 eeeee eeeee  eeee e  eeeee  eeeee    eeee e   e 
    8e 8  88 8   " 8    8  8  8 8   8 8   8  8    8  8   8  8   8    8    8   8 
    88 8   8 8eeee 8eee 8e 8  8 8   8 8eee8e 8eee 8e 8eee8e 8eee8    8eee 8e  8 
e   88 8   8    88 88   88 8  8 8   8 88   8 88   88 88   8 88  8    88   88  8 
8eee88 8eee8 8ee88 88ee 88 8  8 8eee8 88   8 88ee 88 88   8 88  8 88 88ee 88ee8 
                    

Hey y'all 🤠   My name is José Moreira, nice to meet you! 👋

I'm based in Portugal and I'm Co-founder and TechLead at (https://mosano.eu).
One of my passions is work related with bleeding-edge software, I'm not just
curious about how tech will look like in a couple of years, I want to build it.

This is not a pseudo-terminal, this website is actually running Linux in your
browser. 🤓   I just though it would be fun to create   something different,
and really liked the idea of a terminal-based website that provided the real
terminal experience. I decided to go along with that idea and have its content
represented within the init ram disk's folder-structure.

If you have experience with Unix-based Operating Systems, give it a try. Might
or might not have some easter eggs included. 😉

PS: My team builds awesome stuff, check out our work at (https://mosano.eu).

Contacts
========

Github:    https://github.com/cusspvz
LinkedIn:  https://linkedin.com/in/cusspvz

Lets have this thing happening, starting now:
=============================================

`