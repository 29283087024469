export function getFiles() {
    // @ts-ignore
    const required = require.context('!raw-loader!../filesystem');

    return required.keys().map(file => {
        const filename = file.replace(/^\.\//, '/');
        const content = required(file).default || '';

        return [filename, content]
    })
}

export function feedFilesToFs9p(fs9p: any) {
    const textenc = new TextEncoder();
    const files = getFiles();

    for(const [path, content] of files) {
        const { parentid } = fs9p.SearchPath(path)

        const parts = path.split('/')
        const filename = parts[parts.length - 1]
        const not_found = filename === '' || parentid === -1

        if (!not_found) {
            fs9p.CreateBinaryFile(filename, parentid, textenc.encode(content));
        }
    }
}