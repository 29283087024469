import { useEffect, useMemo, useState } from 'react'
import {useScript} from './use-script'
import {noop} from '../utils/noop'
import microtick from 'performance-now'
import type { Terminal } from 'xterm'
import { feedFilesToFs9p, getFiles } from '../utils/fs2json'
import { loadFileBuffer } from '../utils/load-file-buffer'
import mem from 'mem'

// import "../../tools/image-builder/"

const SECONDS_TO_AUTOSTART = 5

interface Dependencies {
    bios: ArrayBuffer
    bzImage: ArrayBuffer
}

const loadDependencies = mem(async (term: Terminal) => {
    term.writeln(`Dependencies Loader - starting...`)
    
    const loadDep = async (module: string) => {
        term.writeln(`Dependencies Loader - ${module}: Starting download...`)

        // todo: maybe write some progress into the terminal
        const buffer = await loadFileBuffer(module)

        term.writeln(`Dependencies Loader - ${module}: is now loaded`)

        return buffer
    }

    const [bios, bzImage] = await Promise.all([
        loadDep('/bios/seabios.bin'),
        loadDep('/os/buildroot-bzimage.bin'),  
    ])
    
    term.writeln(`Dependencies Loader - dependencies are in!`)

    return {
        bios, bzImage
    }
}, {
    cacheKey: () => `same`
})

export function useEmulator(term: Terminal | null) {
    const [emulator, setEmulator] = useState<any | null>(null)
    const [dependenciesLoaded, setDependenciesLoaded] = useState<Dependencies | undefined>(undefined)

    useEffect(() => {
        if (typeof document == "undefined") return noop
        if (!term) return noop

        // on first load, attempt to load the dependencies
        
        loadDependencies(term).then((dependencies) => {
            if (!dependenciesLoaded) {
                setDependenciesLoaded(dependencies)
            }
        })
    }, [term])

    const v86ScriptStatus = useScript('/v86.js')
    useEffect(() => {
        if (typeof document == "undefined") return noop
        if (v86ScriptStatus !== 'ready') return noop
        if (!dependenciesLoaded) return noop

        // @ts-ignore
        const emulator = new window.V86Starter({
            microtick,
            wasm_path: "/v86.wasm",
            network_relay_url: "wss://relay.widgetry.org/",
            disable_keyboard: true,
            memory_size: 32 * 1024 * 1024,
            vga_memory_size: 1 * 1024 * 1024,

            bios: { buffer: dependenciesLoaded.bios },
            bzimage: { buffer: dependenciesLoaded.bzImage },
            cmdline: 'tsc=reliable mitigations=off random.trust_cpu=on TERM=xterm-256color LANG=en_US.UTF-8 LC_ALL=en_US.UTF-8',
            filesystem: {},
        });

        // emulator.bus.register('emulator-ready', () => {
        //     emulator.serial0_send(`source /mnt/init;`)
        // })

        setEmulator(emulator)
        // @ts-ignore
        window.emulator = emulator

        return () => {
            emulator?.stop()
            emulator?.destroy()
            setEmulator(null)
        }
    }, [v86ScriptStatus, dependenciesLoaded])

    // bind emulator with the term 
    useEffect(() => {
        if (typeof document == "undefined") return noop
        if (!emulator || !term) return noop

        const writeToTerm = (chr) => {
            // console.log(chr)
            term.write(chr)
        }

        const disposeEvent = term.onData((data) => emulator.serial0_send(data));
        emulator.bus.register("serial0-output-char", writeToTerm);

        return () => {
            disposeEvent?.dispose()
            emulator?.bus?.unregister("serial0-output-char", writeToTerm)
        }
    }, [emulator, term])

    // start the emulator once a key is pressed
    useEffect(() => {
        if (typeof document == "undefined") return noop
        if (!emulator || !term) return noop
        if (!dependenciesLoaded) return noop

        const startEmulator = () => {
            if(!emulator.is_running()) {
                term.write('\nBooting Linux image...\n')
                emulator.run()

                // feed fs9p with files
                feedFilesToFs9p(emulator.fs9p)

                disposeEvent?.dispose()
            }
        }

        const disposeEvent = term.onData((data) => {
            if(data.charCodeAt(0) === 13) {
                startEmulator()
            }
        });

        // auto start in 5 secs
        (async function () {
            term.write(`Press [Enter] to boot. Autobooting in ${SECONDS_TO_AUTOSTART} seconds`)

            for (let i = 0; i < SECONDS_TO_AUTOSTART; i++) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                if (emulator.is_running()) return
                term.write('.')
            }

            startEmulator()
        })()


        return () => {
            disposeEvent?.dispose()
        }
    }, [term, emulator, dependenciesLoaded])

    return emulator
}
