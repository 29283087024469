import mem from 'mem'

export const loadFileBuffer = mem(async (url: string) => {
    const res = await fetch(url)

    if (!res.ok) {
        throw new Error('got invalid response from the server')
    }

    return res.arrayBuffer()
})